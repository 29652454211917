<template>
  <div class="overview-home">
    <baseRow>
      <baseCol :num="18">
        <div class="latest-statistics">
          <div class="base-bg">
            <baseTitle name="最新统计" />
            <baseRow>
              <baseCol>
                <section class="latest-status">
                  <div>
                    <span class="iconfont icon-zongtijiaoshu status-complete"></span>
                  </div>
                  <div>
                    <span class="status-num">{{ dataTotall.totalSubmitNumber }}</span>
                    <p>总提交数</p>
                  </div>
                </section>
              </baseCol>
              <baseCol>
                <section class="latest-status">
                  <div>
                    <span class="iconfont icon-zongbanjieshu status-sub"></span>
                  </div>
                  <div>
                    <span class="status-num">{{ dataTotall.totalFinishNumber }}</span>
                    <p>总办结数</p>
                  </div>
                </section>
              </baseCol>
              <baseCol>
                <section class="latest-status">
                  <div>
                    <span class="iconfont icon-lishijinhangzhong status-process"></span>
                  </div>
                  <div>
                    <span class="status-num">{{ dataTotall.totalhandingNumber }}</span>
                    <p>历史进行中</p>
                  </div>
                </section>
              </baseCol>
              <baseCol>
                <section class="latest-status">
                  <div>
                    <span class="iconfont icon-dangyuetijiaoshuliang1 status-sub"></span>
                  </div>
                  <div>
                    <span class="status-num">{{ dataTotall.currentMonthSubmitNumber }}</span>
                    <p>当月提交数</p>
                  </div>
                </section>
              </baseCol>
              <baseCol>
                <section class="latest-status">
                  <div>
                    <span class="iconfont icon-dangyuebanjieshu1 status-complete"></span>
                  </div>
                  <div>
                    <span class="status-num">{{ dataTotall.currentMonthFinishNumber }}</span>
                    <p>当月办结数</p>
                  </div>
                </section>
              </baseCol>
              <baseCol>
                <section class="latest-status">
                  <div>
                    <span class="iconfont icon-zhengzaijinhang status-process"></span>
                  </div>
                  <div>
                    <span class="status-num">{{ dataTotall.currentMonthHandlingNumber }}</span>
                    <p>当月进行中</p>
                  </div>
                </section>
              </baseCol>
            </baseRow>
          </div>
          <div class="latest-line-chart">
            <lineChart />
          </div>
        </div>
      </baseCol>
      <baseCol :num="6">
        <div class="latest-news">
          <baseTitle name="动态提醒" />
          <span class="el-table__empty-text" v-if="processing.length === 0">暂无数据</span>
          <el-timeline>
            <el-timeline-item v-for="(item, index) in processing" :key="index" :timestamp="formatDate(item.sendTime)" placement="top">
              <div class="card-info">
                <div class="info-left flex-between">
                  <div class="user-box">
                    <el-avatar :size="28" :src="item.operateUserHeadImage || defaultImg"></el-avatar>
                    <div class="user-info">
                      <strong>{{ item.operateUserName }}</strong>
                      <span>{{ item.department }}</span>
                    </div>
                  </div>
                  <!-- <span class="status">{{ item.actionName.replace(/给/, '') }}</span> -->
                </div>
                <!-- <p class="ellipsis">{{ item.title }}</p> -->
                <div class="ellipsisTwo">
                  {{ item.actionName.replace(/给/, '了') }} <span class="status-wait">{{ item.orderClassficationName }} | {{ item.matterClassficationName }}</span> 事项：<span
                    class="status-wait"
                    >{{ item.title }}</span
                  >
                </div>
                <!-- <div class="handle-info">
                  <span>{{ item.matterPropertyName }}</span> <span>{{ item.orderClassficationName }}</span> <span>{{ item.matterClassficationName }}</span>
                </div> -->
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </baseCol>
    </baseRow>
    <baseRow>
      <baseCol :num="6">
        <div class="order-classify main-box-white mt16 mr16">
          <baseTitle name="历史一级分类" text="" />
          <div class="pie">
            <pieChart />
          </div>
        </div>
      </baseCol>
      <baseCol :num="6">
        <div class="order-complete main-box-white mt16 mr16">
          <baseTitle name="历史二级分类" text="" />
          <div class="pie">
            <pieChartTwo />
          </div>
        </div>
      </baseCol>
      <baseCol :num="6">
        <div class="order-area main-box-white mt16 mr16">
          <baseTitle name="历史区域分布" text="" />
          <div class="pie">
            <pieChartThree />
          </div>
        </div>
      </baseCol>
      <baseCol :num="6">
        <div class="depart-box main-box-white mt16">
          <baseTitle name="近期新增" text="近30日" />
          <span class="el-table__empty-text" v-if="departmentList.length === 0">暂无数据</span>
          <div class="department-item" v-for="(item, index) in departmentList" :key="index">
            <div class="item-top">
              <h5 class="depart">{{ item.department }}</h5>
              <span>{{ item.number }}</span>
            </div>
            <div class="item-content">
              <div class="gorup">
                <div v-for="(img, index) in item.images" :key="index">
                  <img :src="img" :onerror="errorImg" />
                </div>
                <!-- <div><span>2</span></div>
                <div><span>3</span></div>
                <div><span>4</span></div>
                <div><span>5</span></div>
                <div><span>6</span></div>
                <div><span>7</span></div> -->
              </div>
              <!-- <i class="el-icon-arrow-right"></i> -->
            </div>
          </div>
        </div>
      </baseCol>
    </baseRow>

    <baseRow>
      <div class="lastest-orders main-box-white mt16">
        <baseTitle name="最新事项" />
        <base-table :data="data" :columns="columns" :operations="operations" :isCheck="isCheck" :multi="false" />
      </div>
    </baseRow>
  </div>
</template>

<script>
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
import baseTitle from '@/components/title/base-title';
import lineChart from '@/components/line-chart/line-chart';
import pieChart from '@/components/pie-chart/pie-chart';
import pieChartTwo from '@/components/pie-chart/pie-chart-two';
import pieChartThree from '@/components/pie-chart/pie-chart-three';
import baseTable from '@/components/table/base-table/base-table';
import { table } from './mixin';
export default {
  props: ['id'],
  mixins: [table],
  data() {
    return {
      defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      processing: [],
      dataTotall: {
        totalSubmitNumber: 0,
        totalFinishNumber: 0,
        totalhandingNumber: 0,
        currentMonthSubmitNumber: 0,
        currentMonthFinishNumber: 0,
        currentMonthHandlingNumber: 0
      },
      departmentList: [],
      translateX: '0px',
      showArrow: false,
      currentLength: 5,
      currentX: 0,
      data: []
    };
  },
  created() {
    this.getMsg();
    this.getLatestStatistics();
    this.getDeptStatistics();
    this.listCompanyNew();
  },
  computed: {
    errorImg() {
      return `this.src="${require('@/assets/img/default.png')}"`;
    }
  },
  methods: {
    async listCompanyNew() {
      const { data } = await this.$apis.home.listCompanyNew();
      this.data = data;
    },
    async getMsg() {
      const { data } = await this.$apis.home.getMessage({ page: 1, limit: 3 });
      this.processing = data;
    },
    async getLatestStatistics() {
      const { data } = await this.$apis.home.getLatestStatistics();
      this.dataTotall = data;
    },
    async getDeptStatistics() {
      const { data } = await this.$apis.home.getDeptStatistics();
      data.forEach(item => {
        this.departmentList.push({
          department: item.deptName,
          number: item.additionalNumber,
          images: item.reporterAvatars.split(',')
        });
      });
    },
    view({ id, status }) {
      if (status !== 'completed' && status !== 'handledFailure') {
        this.$router.push({ name: 'working_detail', params: { id } });
      } else {
        this.$router.push({ name: 'completed_detail', params: { id } });
      }
    },
    departMoveLeft() {
      if (this.currentLength === this.departmentList.length) {
        return;
      }
      this.currentLength = this.currentLength + 1;
      this.currentX = this.currentX + 325;
      this.translateX = `translateX(-${this.currentX}px)`;
    },
    departMoveRight() {
      if (this.currentLength === 5) {
        return;
      }
      this.currentLength = this.currentLength - 1;
      this.currentX = this.currentX - 325;
      this.translateX = `translateX(-${this.currentX}px)`;
    }
  },
  components: {
    baseRow,
    baseCol,
    baseTitle,
    lineChart,
    pieChart,
    pieChartTwo,
    pieChartThree,
    // barChart,
    baseTable
    // plieChart
  }
};
</script>

<style lang="scss" scoped>
.base-bg,
.latest-line-chart,
.latest-news {
  padding: 20px;
  background: #fff;
  border-radius: 6px;
}
.base-bg {
  margin-right: 16px;
}
.latest-line-chart {
  margin-top: 16px;
  margin-right: 16px;
  // width: 100%;
  height: 362px;
}
.latest-status {
  // width: 184px;
  height: 96px;
  margin-right: 10px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  .status-num {
    font-size: 30px;
    display: block;
    text-align: right;
    font-family: YouSheBiaoTiHei;
  }
  p {
    color: #5a5a5a;
    font-size: 14px;
    line-height: 0.5;
  }
  .iconfont {
    font-size: 42px;
  }
  .icon-zongtijiaoshu,
  .icon-zongbanjieshu {
    font-size: 40px;
  }
  .icon-dangyuebanjieshu1 {
    font-size: 45px;
  }
  .status-process {
    color: #19a572;
  }
  .status-sub {
    color: #438edb;
  }
  .status-complete {
    color: #f39800;
  }
}
.latest-news {
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  height: 556px;
  .card-info {
    padding: 10px 0;
  }
  .info-left {
    display: flex;
    align-items: center;
    .user-info {
      margin-left: 10px;
      span {
        margin-left: 10px;
        color: #909399;
      }
    }
  }
  p,
  .handle-info {
    padding-left: 38px;
    span {
      display: inline-block;
      padding: 0 10px;
      margin-right: 16px;
      margin-top: 10px;
      height: 22px;
      background: #f3f3f3;
      border-radius: 11px;
    }
  }
  p {
    line-height: 35px;
  }
}
.lastest-orders {
  width: 100%;
}
.mt16 {
  margin-top: 16px;
}
.mr16 {
  margin-right: 16px;
}
.main-box-white {
  overflow: hidden;
  height: 397px;
}
.pie {
  width: 350px;
  height: 350px;
}
.department-item {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  margin-bottom: 16px;
  .item-top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    h5 {
      font-weight: normal;
      font-size: 14px;
    }
    span {
      font-family: YouSheBiaoTiHei;
    }
  }
  .item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
}
.gorup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  > div {
    margin-bottom: 10px;
    margin-right: 1px;
    width: 20px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
  }
  > div > img {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: gainsboro;
    border-radius: 50%;
    border: 1px solid #ebebeb;
  }
}
.ellipsisTwo {
  margin-top: 15px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 300;
  line-height: 1.8rem;
}
.flex-between {
  justify-content: space-between;
  display: flex;
}
.user-box {
  display: flex;
  align-items: center;
}
.status {
}
</style>
