<template>
  <div class="base-table">
    <el-table :data="data" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="55" v-if="multi"></el-table-column>
      <el-table-column label="序号" align="center" width="55" v-if="isCheck">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column v-for="column in scopeColumns" :key="column.prop" :prop="column.prop" :label="column.label" :width="column.width" :align="column.align || 'left'">
        <!-- <template slot-scope="scope" v-if="column.noUser">
          <div class="column_href" @click="column.handle(scope.row)" v-if="column.href"><i class="iconfont icon-dcicon_link"></i>{{ scope.row[column.prop] }}</div>
          <div class="ellipsis" v-else>{{ scope.row[column.prop] }}</div>
        </template> -->
        <template slot-scope="scope">
          <div class="scope-box">
            <div v-if="!column.noUser" class="relative"><img class="avatar" :src="scope.row[column.avatar]" /><i class="avatar"></i></div>
            <div class="">
              <template v-if="!column.hasInfo">
                <span
                  class="table-dot table-dot-grey"
                  :class="{
                    'table-dot-org': scope.row[column.prop] == '1' || scope.row[column.prop] === '待处理',
                    'table-dot-blue': scope.row[column.prop] === '待验收',
                    'status-success': scope.row[column.prop] === '验收完成',
                    'status-fail': scope.row[column.prop] === '暂不受理'
                  }"
                  v-if="column.dot"
                ></span
                ><span v-if="column.switch"
                  ><el-switch
                    v-model="scope.row[column.prop]"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#f39800"
                    inactive-color="#DCDCDC"
                    @change="changeVisite(scope.row)"
                    v-if="scope.row[column.status] == '验收完成'"
                  >
                  </el-switch></span
                ><span v-else-if="column.time">{{ formatDate(scope.row[column.prop]) }}</span>
                <span v-else-if="column.isScore">{{ evaluatTxt[scope.row[column.prop]] }}</span>
                <span v-else-if="column.number">{{ scope.row[column.prop] == 1 ? '在职' : '离职' }}</span>
                <el-tooltip v-else-if="column.tooltip" class="item" effect="dark" placement="top-start">
                  <div slot="content" style="max-width: 800px; font-size: 14px">{{ scope.row[column.prop] }}</div>
                  <span>{{ scope.row[column.prop] }}</span>
                </el-tooltip>
                <span v-else :class="{ ellipsisTitle: column.prop == 'title' }">{{ scope.row[column.prop] }}</span>
                <span class="scope-phone" v-if="!column.noUser">{{ scope.row[column.other] }}</span>
              </template>
              <template v-else>
                <div class="split-box">
                  <div class="split-info">
                    <span>{{ scope.row[column.other[0]] }}</span> <span class="fr-txt">{{ formatDate(scope.row[column.other[1]]) }}</span>
                  </div>
                  <span class="split-content">{{ scope.row[column.other[2]] }}</span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" v-if="hasHandle">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="header" slot-scope="scope">
          <span class="el-dropdown-link">操作</span>
          <el-dropdown trigger="click">
            <i class="iconfont icon-shaixuan"></i>
            <el-dropdown-menu slot="dropdown">
              <el-checkbox-group v-model="checkColumns">
                <el-checkbox v-for="column in columns" :key="column.prop" :label="column.label"></el-checkbox>
              </el-checkbox-group>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <div v-if="isMore">
            <el-button type="text" size="small" v-for="{ icon, name, handle } in moreBefore" :key="icon" @click="handle(scope.row)">
              <el-tooltip :content="name" placement="top">
                <i :class="icon" class="iconfont">{{ scope.row.workStatus }}</i>
              </el-tooltip>
            </el-button>
            <el-dropdown trigger="click">
              <el-button type="text" size="small">
                <el-tooltip content="更多" placement="top">
                  <i :class="TABLE.icons.more" class="iconfont"></i>
                </el-tooltip>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="text-align: center" v-for="{ icon, name, handle } in moreAfter" :key="icon">
                  <div @click="handle(scope.row)">
                    <i class="iconfont"></i>
                    <span> {{ name }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-else>
            <template v-if="scope.row.workStatus == 0 || scope.row.workStatus == 1">
              <el-button type="text" size="small" v-for="{ icon, name, handle } in moreBefore" :key="icon" @click="handle(scope.row)">
                <el-tooltip content="复职" placement="top" v-if="name == '复职' && scope.row.workStatus == 0">
                  <i class="icon-bianji iconfont" style="font-size: 18px; position: relative; left: 10px"></i>
                </el-tooltip>
                <el-tooltip content="离职" placement="top" v-else-if="name == '离职' && scope.row.workStatus == 1">
                  <i class="icon-shanchu iconfont"></i>
                </el-tooltip>
                <el-tooltip :content="name" placement="top" v-else-if="name !== '复职' && name !== '离职'">
                  <i :class="icon" class="iconfont"></i>
                </el-tooltip>
              </el-button>
            </template>
            <template v-else>
              <el-button type="text" size="small" v-for="{ icon, name, handle } in moreBefore" :key="icon" @click="handle(scope.row)">
                <el-tooltip :content="name" placement="top">
                  <i :class="icon" class="iconfont"></i>
                </el-tooltip>
              </el-button>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { SIMULATE_NETWORK_TIME, TABLE } from '/app.config';
export default {
  props: {
    // 是否支持多选
    multi: {
      type: Boolean,
      default: true
    },
    noUser: {
      type: Boolean,
      default: true
    },
    isCheck: {
      type: Boolean,
      default: true
    },
    hasHandle: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    // 表格的 columns
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    // 表格的操作
    operations: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      TABLE,
      height: '682',
      loading: false,
      scopeColumns: this.columns,
      checkColumns: this.columns.map(column => column.label),
      evaluatTxt: {
        '-2': '非常不满意',
        '-1': '不满意',
        0: '一般',
        1: '满意',
        2: '非常满意'
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // if (document.querySelector('.list-container > .main')) {
      //   this.height = document.querySelector('.list-container > .main').clientHeight;
      // } else {
      //   this.height = 'auto';
      // }
    });
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('update:multipleSelection', val);
    },
    changeVisite(row) {
      this.$emit('set', row);
    }
  },
  computed: {
    isMore() {
      return this.operations.handles.length > TABLE.more_icons_threshold;
    },
    moreBefore() {
      return this.operations.handles.filter((handle, index) => index < TABLE.more_icons_threshold);
    },
    moreAfter() {
      return this.operations.handles.filter((handle, index) => index >= TABLE.more_icons_threshold);
    }
    // isVisited() {
    //   return false;
    // }
  },

  watch: {
    checkColumns(val) {
      this.loading = true;
      setTimeout(() => {
        this.scopeColumns = this.columns.filter(cloumn => {
          return val.includes(cloumn.label);
        });
        this.loading = false;
      }, SIMULATE_NETWORK_TIME);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import '../table';
.base-table {
  height: 100%;
  .column_href {
    &:hover {
      color: $--color-primary;
      cursor: pointer;
      .icon-dcicon_link {
        color: $--color-primary;
      }
    }
    .icon-dcicon_link {
      color: #909399;
      margin-right: 8px;
      display: inline-block;
    }
  }
  .relative {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    img {
      z-index: 1;
    }
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background: url(https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png);
    background-size: 100%;
    border-radius: 50%;
    position: absolute;
  }
  // img.avatar {

  // }
  img[src=''],
  img:not([src]) {
    opacity: 0;
  }
  .scope-phone {
    display: block;
    color: #909399;
  }
  .scope-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .split-info {
    display: flex;
    justify-content: space-between;
    .fr-txt {
      // color: #909399;
    }
  }
  .split-content {
    width: 382px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #5a5a5a;
    display: block;
  }
  .split-box {
    padding-right: 10px;
  }
  .table-dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
  .table-dot-grey {
    background: #bdbdbd;
  }
  .table-dot-org {
    background: #f39800;
  }
  .table-dot-blue {
    background: #438edb;
  }
  .status-success {
    background: #19a572;
  }
  .status-fail {
    background: #ff2e2e;
  }
  .ellipsisTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 400px;
    display: block;
  }
}
</style>
