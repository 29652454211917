<template>
  <div id="line-chart" class="line-chart"><span class="el-table__empty-text" v-if="finishNumber.length === 0 && handlingNumber.length === 0">暂无数据</span></div>
</template>

<script>
import * as echarts from 'echarts';
// import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, MarkLineComponent, MarkPointComponent } from 'echarts/components';
// import { BarChart } from 'echarts/charts';
// import { CanvasRenderer } from 'echarts/renderers';
export default {
  data() {
    return {
      device_data: [],
      finishNumber: [],
      handlingNumber: []
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    async getStatus() {
      const { data } = await this.$apis.home.getStatus();
      this.finishNumber = data.finishNumber;
      this.handlingNumber = data.handlingNumber;
      this.chartLine();
    },
    chartLine() {
      let finishNumber = this.finishNumber.split(',');
      let handlingNumber = this.handlingNumber.split(',');
      let lineChart = echarts.init(document.querySelector('.line-chart'));
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          name: '月',
          axisTick: {
            show: false
          },

          type: 'category',
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '已完成事项',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'green' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'white' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            barWidth: '12px',
            data: finishNumber
          },
          {
            name: '进行中事项',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#f39800' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'white' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            data: handlingNumber
          }
        ]
      };
      option && lineChart.setOption(option);
    }
  }
};
</script>
<style lang="scss" scoped>
.line-chart {
  width: 100%;
  height: 100%;
}
</style>
