<template>
  <div id="pie-chart" class="pie-chart"><span class="el-table__empty-text" v-if="pieData.length === 0">暂无数据</span></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      device_data: [],
      pieData: [],
      totall: 0
    };
  },
  mounted() {
    this.getTotall();
  },
  methods: {
    async getTotall() {
      const { data } = await this.$apis.home.getTotall();
      for (let key in data) {
        this.pieData.push({
          value: data[key],
          name: key
        });
        this.totall = parseInt(this.totall) + parseInt(data[key]);
      }
      if (this.pieData.length > 0) {
        this.chartPie();
      }
    },
    chartPie() {
      let pieData = this.pieData;
      let totall = this.totall;
      let pieChart = echarts.init(document.querySelector('.pie-chart'));
      const option = {
        title: {
          zlevel: 0,
          text: [`历史总数`, '', totall].join('\n'),
          top: '32%',
          left: '49%',
          textAlign: 'center'
        },
        tooltip: {
          trigger: 'item',
          position: ['50%', '50%']
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            zlevel: 1,
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            startAngle: 270,
            data: pieData
          }
        ]
      };
      option && pieChart.setOption(option);
    }
  }
};
</script>
<style lang="scss" scoped>
.pie-chart {
  width: 100%;
  height: 100%;
}
</style>
