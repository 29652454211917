<template>
  <div id="pie-chart-two" class="pie-chart-two"><span class="el-table__empty-text" v-if="pieData.length === 0">暂无数据</span></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      device_data: [],
      pieData: []
    };
  },
  mounted() {
    this.getMatter();
  },
  methods: {
    async getMatter() {
      const { data } = await this.$apis.home.getMatter();
      for (let key in data) {
        this.pieData.push({
          value: data[key],
          name: key
        });
      }
      if (this.pieData.length > 0) {
        this.chartPie();
      }
    },
    chartPie() {
      let pieData = this.pieData;
      let pieChart = echarts.init(document.querySelector('.pie-chart-two'));
      const option = {
        tooltip: {
          trigger: 'item',
          position: ['40%', '50%']
        },
        legend: {
          show: false
        },
        grid: {
          top: 1
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            center: ['50%', '40%'],
            data: pieData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && pieChart.setOption(option);
    }
  }
};
</script>
<style lang="scss" scoped>
.pie-chart-two {
  width: 100%;
  height: 100%;
}
</style>
