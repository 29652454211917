<template>
  <el-col class="col" :xl="num" :lg="num" :md="num" :sm="num" :xs="num" :push="push" :pull="pull">
    <slot></slot>
  </el-col>
</template>

<script>
export default {
  props: {
    push: Number,
    pull: Number,
    num: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.col {
  word-break: break-all;
}
</style>
