<template>
  <div id="pie-chart-three" class="pie-chart-three"><span class="el-table__empty-text" v-if="pieData.length === 0">暂无数据</span></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      device_data: [],
      pieData: []
    };
  },
  mounted() {
    this.getLocation();
  },
  methods: {
    async getLocation() {
      const { data } = await this.$apis.home.getLocation();
      for (let key in data) {
        if (data[key] !== 0) {
          this.pieData.push({
            value: data[key],
            name: key
          });
        }
      }
      if (this.pieData.length > 0) {
        this.chartPie();
      }
    },
    chartPie() {
      let pieData = this.pieData;
      let pieChart = echarts.init(document.querySelector('.pie-chart-three'));
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false
        },
        toolbox: {
          show: true
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: [20, 100],
            center: ['50%', '40%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 0
            },
            data: pieData
          }
        ]
      };
      option && pieChart.setOption(option);
    }
  }
};
</script>
<style lang="scss" scoped>
.pie-chart-three {
  width: 100%;
  height: 100%;
}
</style>
