<template>
  <div class="base-title">
    <div class="section">
      <p class="name clearfix">
        <span class="text-name">{{ name }}</span>
        <span class="fr right">{{ text }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    text: String,
    tips: String
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.base-title {
  .section {
    .name {
      font-size: 16px;
      position: relative;
      // border-bottom: 1px solid #ebebeb;
      padding-bottom: 8px;
      margin-bottom: 10px;
      color: #121212;
      span {
        padding-bottom: 8px;
      }
      .text-name {
        // font-weight: bold;
      }
      .right {
        font-size: 14px;
        color: #909399;
      }
    }
  }
}
</style>
